<template>
  <div class="yy-banner-wrapper">
    <div class="yy-banner">
      <q-banner>
        <template v-slot:avatar>
          <div class="yy-big-logo"></div>
        </template>
        {{ prompt }}
        <template v-if="done" v-slot:action>
          <q-btn flat :label="nextAction" :to="nextUrl" />
        </template>
      </q-banner>
    </div>
  </div>
  <q-ajax-bar position="top" color="main-color1" size="0.625rem" />
</template>

<script>
import { active } from "@api/auth-api";
export default {
  name: "Active",

  data () {
    return {
      prompt: "欢迎来到云舆标记网站，正在进行账号激活操作，请稍等...",
      nextAction: "前往主页",
      nextUrl: "/",
      done: false,
    };
  },
  mounted () {
    const activeCode = this.$route.params.activecode;
    console.log("active code", activeCode);
    active({ activeCode })
      .then((res) => {
        this.prompt = "您云舆标记账号已经成功激活！";
        this.done = true;
        this.nextAction = "前往登录";
        this.nextUrl = "/activelogin/" + res;
      })
      .catch((err) => {
        console.log(err);
        this.prompt = "云舆标记账号激活操作失败，原因：" + err.description;
        this.done = true;
      });
  },
};
</script>
<style lang="sass" scoped>
@import "@sass/quasar.sass"
.yy-banner-wrapper
  position: relative
  height: 100vh
  .yy-banner
    height: 50px
    width: 80%
    @include pos-center
    top: -38%

    .q-banner
      background-color: $dark-color1
      color: $gray-color1
      .yy-big-logo
        width: 68px
        height: 68px
        border-radius: 50%
        background: $dark-color1 center center/cover no-repeat url("~@img/logo-big.svg")
</style>
